import React from 'react';
import { useNavigate } from 'react-router-dom';

const AboutPage = () => {
  const navigate = useNavigate();

  return (
    <article className='about'>
      <h1>About Watch Streams: Your Ultimate Multistream Viewer for Twitch and YouTube</h1>
      <p>Welcome to Watch Streams, the ultimate platform for seamlessly watching multiple Twitch and YouTube live streams at the same time. Whether you're a dedicated fan, an engaged viewer, or a proactive streamer, Watch Streams is designed to enhance your live-streaming experience. Say goodbye to switching between tabs or missing out on the action—our intuitive service lets you bring all your favorite live broadcasts into one convenient screen.</p>
      <h2>Key Features of Watch Streams</h2>
      <ul>
        <li><h3>Watch Twitch and YouTube Simultaneously:</h3> Effortlessly view live streams from Twitch and YouTube side-by-side. Perfect for gaming marathons, live events, or keeping up with your favorite creators across platforms.</li>
        <li><h3>Flexible Layouts:</h3> Customize your screen to prioritize the streams that matter most. Adjust layouts to suit your needs, whether you're multitasking or fully immersed in a single stream.</li>
        <li><h3>Chat Control:</h3>
          <ul>
            <li><em>Hide Chat:</em> Minimize distractions by hiding chat for one or all streams.</li>
            <li><em>Chat-Only Mode:</em> Moderators and broadcasters can focus on engaging with their communities while keeping streams in the background. Manage multiple chat rooms without breaking a sweat.</li>
          </ul>
        </li>
        <li><h3>Focus Mode:</h3> Need to zero in on one stream? Use Focus Mode to expand a single video while keeping all chats accessible. Switching focus to another stream is just a click away.</li>
        <li><h3>Full Mobile Web Support:</h3> Take Watch Streams on the go. Our mobile-optimized platform ensures smooth performance on your phone or tablet, so you never miss a moment, no matter where you are.</li>
      </ul>
      <h2>For Broadcasters and Moderators</h2>
      <p>Watch Streams isn't just for viewers—it's an essential tool for Twitch and YouTube broadcasters. Whether you're managing your community or collaborating with other creators, our platform simplifies multitasking and streamlining communication.</p>
      <ul>
        <li><strong>Monitor Multiple Chats Simultaneously:</strong>With Chat-Only Mode, you can keep track of all your chat rooms across Twitch and YouTube without needing to open multiple tabs or devices. This makes moderating your community more efficient and less distracting.</li>
        <li><strong>Collaborate with Other Broadcasters:</strong>If you're gaming or hosting content with other streamers, Watch Streams lets you display their streams and chats alongside your own. This way, you can monitor the action and interact with everyone involved in real-time.</li>
        <li><strong>Rebroadcast and Stay in Control:</strong>Focus Mode is perfect for broadcasters who rebroadcast other streams or gameplay collaborations. Instantly switch focus between streams to highlight your teammates, competitors, or event participants, all while keeping chats visible and accessible.</li>
      </ul>
      <h2>Why Choose Watch Streams?</h2>
      <p>Watch Streams isn't just a multistream viewer; it's your go-to tool for enjoying Twitch and YouTube live streams like never before. We prioritize viewer and broadcaster convenience, making it easy to stay connected to multiple communities while still offering control over your viewing experience. Whether you're following esports tournaments, hosting virtual watch parties, or collaborating with other broadcasters, Watch Streams puts everything at your fingertips.</p>
      <p><strong>Experience the best way to watch Twitch and YouTube live streams together. Join the Watch Streams community today and elevate your multistreaming experience!</strong></p>
      <p>This updated description highlights the broadcaster-specific features and uses, ensuring your platform appeals to both viewers and streamers, while maximizing SEO for Twitch, YouTube, and multistream-related terms.</p>

      <div className='get-started'>
      <button
          className="button primary"
          onClick={() => navigate('/')}
        >
          Get Started
        </button>
      </div>
    </article>
  );
};

export default AboutPage;
