import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomeComponent from './HomeComponent';
import AboutPage from './about';
import ErrorPage from './error';
import StreamComponent from './StreamComponent';
import Callback from './Callback';
import TwitchLoginButton from './TwitchLoginButton';

const RoutesConfig = ({ channelData, showChats, toggleShowChats, chatOnly, isLoaded }) => {
  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route path="/about-multistream-viewer-twitch-youtube" element={<AboutPage />} />
      <Route path="/auth/twitch/callback" element={<Callback />} />
      <Route path="/login" element={<TwitchLoginButton />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route
        path="/:usernames/*"
        element={<StreamComponent channelData={channelData} showChats={showChats} toggleShowChats={toggleShowChats} chatOnly={chatOnly} isLoaded={isLoaded} />}
      />
      {/* Add a catch-all route to handle navigation back to HomeComponent */}
      <Route
        path="/*"
        element={
          channelData.length > 0 ? (
            <Navigate to="/" state={{ channelData }} isLoaded={isLoaded} />
          ) : (
            <HomeComponent />
          )
        }
      />
    </Routes>
  );
};

export default RoutesConfig;